const QUERIES = {
  USERS_LIST: 'users-list',
  CLIENTS_LIST: 'clients-list',
  CLIENT_CREATE: 'client-create',
  IS_SUCCESSFUL_DEALS_LIST: 'is-successful-deals-list',
  DONE_TASKS_LIST: 'done-task-list',
  IS_SUCCESSFUL_DEAL_ORDERS_LIST: 'is-successful-deal-orders-list',
  BLOG_ARTICLES_LIST: 'blog-articles-list',
  EXCHANGE_RATES_LIST: 'exchange-rates-list',
  IS_SUCCESSFUL_TOURS_LIST: 'is-successful-tour-orders-list',
  INVOICES_LIST: 'invoices-list',

};

export {QUERIES};
