import {AxiosResponse} from 'axios';
import {initHttpClient} from '../../../../lib/_requests';
import {ExchangeRatesPaginatedQueryResponse, ExchangeRateResponse} from './_models';

export type ID = undefined | null | number;

const API_URL = process.env.REACT_APP_API_URL;
const GET_EXCHANGE_RATES_PAGINATED_LIST_URL = `${API_URL}/exchange_rate/list?${Date.now()}&`;
const MANAGE_EXCHANGE_RATE_URL = `${API_URL}/exchange_rate/manage`;
const GET_EXCHANGE_RATE_BY_ID = `${API_URL}/exchange_rate/find?${Date.now()}&`;
const GET_AVG_EXCHANGE_RATE = `${API_URL}/exchange_rate/avg?${Date.now()}&`;
const GET_CURRENT_EXCHANGE_RATE = `${API_URL}/exchange_rate/current?${Date.now()}&`;

const getPaginatedExchangeRates = (query: string): Promise<ExchangeRatesPaginatedQueryResponse> => {
  const httpClient = initHttpClient();

  return httpClient
    .get(`${GET_EXCHANGE_RATES_PAGINATED_LIST_URL}${query}`)
    .then((d: AxiosResponse<ExchangeRatesPaginatedQueryResponse>) => d.data)
    .catch(function (error) {
      console.log('getExchangeRates ::: ', error);
      if (error.response) {
        if (error.response.status && error.response.status === 500) {
          window.location.href = `/logout`;
        }
        console.log('error.response ::: ', error.response.status);
      } else if (error.request) {
        console.log('error.request ::: ', error.request);
      } else if (error.message) {
        console.log('error.message ::: ', error.message);
      }
      throw error;
    });
};

const saveExchangeRate = (payload: object) => {
  const httpClient = initHttpClient();

  return httpClient
    .post(MANAGE_EXCHANGE_RATE_URL, payload)
    .then((response: AxiosResponse<ExchangeRateResponse>) => response.data)
    .then((response: ExchangeRateResponse) => response.data)
    .catch(function (error) {
      if (error.response) {
        console.log('error.response ::: ', error.response);

        if (error.response.status && error.response.status === 500) {
          window.location.href = `/logout`;
        }

        let response: any = {};
        response.errors = [];
        if (error.response.data.message) {
          response.errors.push(error.response.data.message);
        } else {
          response = error.response.data;
        }

        throw response;
      } else if (error.request) {
        throw error.request;
      } else if (error.message) {
        throw error.message;
      }
      throw error;
    });
};

const fetchExchangeRate = (query: string) => {
  const httpClient = initHttpClient();

  return httpClient
    .get(`${GET_EXCHANGE_RATE_BY_ID}${query}`)
    .then((response: AxiosResponse<ExchangeRateResponse>) => response.data)
    .catch(function (error) {
      if (error.response) {
        if (error.response.status && error.response.status === 500) {
          window.location.href = `/logout`;
        }
        // The request was made and the server responded with a status code
        // that fall s out of the range of 2xx
        let response: any = {};
        response.errors = [];
        if (error.response.data.message) {
          response.errors.push(error.response.data.message);
        } else {
          response = error.response.data;
        }

        throw response;
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        throw error.request;
      } else if (error.message) {
        // Something happened in setting up the request that triggered an Error
        throw error.message;
      }
      throw error;
    });
};

const fetchAvgExchangeRate = (query: string) => {
  const httpClient = initHttpClient();

  return httpClient
    .get(`${GET_AVG_EXCHANGE_RATE}${query}`)
    .then((response: AxiosResponse<ExchangeRateResponse>) => response.data)
    .catch(function (error) {
      if (error.response) {
        if (error.response.status && error.response.status === 500) {
          window.location.href = `/logout`;
        }
        // The request was made and the server responded with a status code
        // that fall s out of the range of 2xx
        let response: any = {};
        response.errors = [];
        if (error.response.data.message) {
          response.errors.push(error.response.data.message);
        } else {
          response = error.response.data;
        }

        throw response;
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        throw error.request;
      } else if (error.message) {
        // Something happened in setting up the request that triggered an Error
        throw error.message;
      }
      throw error;
    });
};

const fetchCurrentExchangeRate = (query: string) => {
  const httpClient = initHttpClient();

  return httpClient
    .get(`${GET_CURRENT_EXCHANGE_RATE}${query}`)
    .then((response: AxiosResponse<ExchangeRateResponse>) => response.data)
    .catch(function (error) {
      if (error.response) {
        if (error.response.status && error.response.status === 500) {
          window.location.href = `/logout`;
        }
        // The request was made and the server responded with a status code
        // that fall s out of the range of 2xx
        let response: any = {};
        response.errors = [];
        if (error.response.data.message) {
          response.errors.push(error.response.data.message);
        } else {
          response = error.response.data;
        }

        throw response;
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        throw error.request;
      } else if (error.message) {
        // Something happened in setting up the request that triggered an Error
        throw error.message;
      }
      throw error;
    });
};

export {
  getPaginatedExchangeRates,
  saveExchangeRate,
  fetchExchangeRate,
  fetchAvgExchangeRate,
  fetchCurrentExchangeRate,
};
