import React, {FC} from 'react';
import {toAbsoluteUrl} from '../../../helpers';
import {Client} from '../../../../app/modules/apps/clients/core/_models';

type Props = {
  searchResults?: Array<Client>;
};

const SearchResult: FC<Props> = ({searchResults}) => {
  return (
    <>
      {searchResults
        ? searchResults.map((item, index) => {
            return (
              <a
                key={item.id}
                href={`/apps/clients/manage/${item.id}`}
                className='d-flex text-dark text-hover-primary align-items-center mb-5'
              >
                <div className='d-flex flex-column justify-content-start fw-bold'>
                  <span className='fs-6 fw-bold'>
                  [ID: {item.id}] {item.other_names} {item.last_name}
                  </span>
                  <span className='fs-7 fw-bold text-muted'>
                    {item.email} | {item.mobile}
                  </span>
                </div>
              </a>
            );
          })
        : 'No client records found'}
    </>
  );
};

export {SearchResult};
