import axios from 'axios';

import {getAuth} from '../modules/auth/core/AuthHelpers';

const API_URL = process.env.REACT_APP_API_URL;

export function initHttpClient() {
  const instance = axios.create({
    baseURL: API_URL,
  });

  const auth = getAuth();
  if (auth && auth.api_token) {
    instance.defaults.headers.common['Authorization'] = auth.api_token;
    instance.defaults.headers.common['Content-Type'] = 'application/json';
  }

  return instance;
}

export function initHttpClientMultipart() {
  const instance = axios.create({
    baseURL: API_URL,
  });

  const auth = getAuth();
  if (auth && auth.api_token) {
    instance.defaults.headers.common['Authorization'] = auth.api_token;
    instance.defaults.headers.common['Content-Type'] = 'multipart/form-data';
  }

  return instance;
}
