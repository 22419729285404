/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react';
import {useIntl} from 'react-intl';
import {toAbsoluteUrl} from '../../../_metronic/helpers';
import {PageTitle} from '../../../_metronic/layout/core';
import {
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  ListsWidget6,
  TablesWidget5,
  TablesWidget10,
  TablesWidget14,
  MixedWidget8,
  MixedWidget16,
  CardsWidget7,
  CardsWidget17,
  CardsWidget20,
  ListsWidget26,
  EngageWidget10,
} from '../../../_metronic/partials/widgets';

const DashboardPage: FC = () => (
  <>
    <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
      {/* <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
        <CardsWidget20
          className='h-md-50 mb-5 mb-xl-10'
          description='Enquiries'
          color='#F1416C'
          img={toAbsoluteUrl('/media/patterns/vector-1.png')}
        />{' '}
        <CardsWidget17 className='h-md-50 mb-5 mb-xl-10' />
      </div>
      
      <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
        <MixedWidget8
          className='card-xxl-stretch h-md-50 mb-5 mb-xl-10'
          chartColor='success'
          chartHeight='150px'
        />
        <MixedWidget16
          className='card-xxl-stretch h-md-50 mb-5 mb-xl-10'
          chartColor='warning'
          chartHeight='150px'
        />
      </div>

      <div className='col-xxl-6'>
        <TablesWidget10 className='card-xxl-stretch mb-5 mb-xl-8' />
      </div> */}
    </div>



    {/* <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
      <div className='col-xxl-12'>
        <TablesWidget14 className='card-xxl-stretch mb-5 mb-xl-8' />
      </div>
    </div> */}



  </>
);

const DashboardWrapper: FC = () => {
  const intl = useIntl();
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  );
};

export {DashboardWrapper};