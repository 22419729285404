import {useEffect, useState} from 'react';
import {Outlet, useLocation} from 'react-router-dom';
import {HeaderWrapper} from './components/header';
import {ScrollTop} from './components/scroll-top';
import {Content} from './components/content';
import {FooterWrapper} from './components/footer';
import {Sidebar} from './components/sidebar';
import {DrawerMessenger, ActivityDrawer, ThemeModeProvider} from '../partials';
import {PageDataProvider} from './core';
import {reInitMenu} from '../helpers';
import {ToolbarWrapper} from './components/toolbar';
import {MasterLayourContext} from './core/MasterLayourContext';

const MasterLayout = () => {
  const [flashNotification, setFlashNotification] = useState('');
  const location = useLocation();
  useEffect(() => {
    reInitMenu();
  }, [location.key]);

  return (
    <MasterLayourContext.Provider value={{flashNotification, setFlashNotification}}>
      <PageDataProvider>
        <ThemeModeProvider>
          <div className='d-flex flex-column flex-root app-root' id='kt_app_root'>
            <div className='app-page flex-column flex-column-fluid' id='kt_app_page'>
              <HeaderWrapper />
              <div className='app-wrapper flex-column flex-row-fluid' id='kt_app_wrapper'>
                <Sidebar />
                <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
                  <div className='d-flex flex-column flex-column-fluid'>
                    <ToolbarWrapper />
                    <Content>
                      <Outlet />
                    </Content>
                  </div>
                  <FooterWrapper />
                </div>
              </div>
            </div>
          </div>

          {/* begin:: Drawers */}
          <ActivityDrawer />
          <DrawerMessenger />
          {/* end:: Drawers */}

          <ScrollTop />
        </ThemeModeProvider>
      </PageDataProvider>
    </MasterLayourContext.Provider>
  );
};

export {MasterLayout};
