/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx';
import React, {FC, useEffect, useRef, useState} from 'react';
import {SearchComponent} from '../../../assets/ts/components';
import {KTSVG} from '../../../helpers';
import {searchClients} from '../../../../app/modules/apps/clients/core/_requests';
import {SearchResult} from './SearchResult';
import {Client} from '../../../../app/modules/apps/clients/core/_models';

type Props = {
  className?: string;
  mobileToggleBtnClass?: string;
};

const Search: FC<Props> = ({className = '', mobileToggleBtnClass = ''}) => {
  const [menuState, setMenuState] = useState<'main' | 'advanced' | 'preferences'>('main');
  const initialSearchResults: Array<Client> = [];
  const [searchResults, setSearchResults] = useState(initialSearchResults);

  const element = useRef<HTMLDivElement | null>(null);
  const wrapperElement = useRef<HTMLDivElement | null>(null);
  const resultsElement = useRef<HTMLDivElement | null>(null);
  const suggestionsElement = useRef<HTMLDivElement | null>(null);
  const emptyElement = useRef<HTMLDivElement | null>(null);
  const loadingElement = useRef<HTMLDivElement | null>(null);
  const initialMessageElement = useRef<HTMLDivElement | null>(null);

  const processs = (search: SearchComponent) => {
    initialMessageElement.current!.classList.add('d-none');
    loadingElement.current!.classList.remove('d-none');
    const loadClients = async () => {
      setSearchResults([]);
      const clients = await searchClients({searchKey: search.getQuery()});
      if (clients && clients.data && clients.data.length > 0) {
        setSearchResults(clients.data);
      } else {
        emptyElement.current!.classList.remove('d-none');
      }

      loadingElement.current!.classList.add('d-none');
    };

    loadClients();

    // Show results
    resultsElement.current!.classList.remove('d-none');
    // Hide empty message
    emptyElement.current!.classList.add('d-none');
    search.complete();
  };

  const clear = (search: SearchComponent) => {
    // Show recently viewed
    // suggestionsElement.current!.classList.remove('d-none');
    // Hide results
    resultsElement.current!.classList.add('d-none');
    // Hide empty message
    emptyElement.current!.classList.add('d-none');
    loadingElement.current!.classList.add('d-none');

    initialMessageElement.current!.classList.remove('d-none');
  };

  useEffect(() => {
    // Initialize search handler
    const searchObject = SearchComponent.createInsance('#kt_header_search');

    // Search handler
    searchObject!.on('kt.search.process', processs);

    // Clear handler
    searchObject!.on('kt.search.clear', clear);
  }, []);

  return (
    <div
      id='kt_header_search'
      className={clsx('d-flex align-items-center', className)}
      data-kt-search-keypress='true'
      data-kt-search-min-length='4'
      data-kt-search-enter='enter'
      data-kt-search-layout='menu'
      data-kt-search-responsive='lg'
      data-kt-menu-trigger='auto'
      data-kt-menu-permanent='true'
      data-kt-menu-placement='bottom-end'
      data-kt-search='true'
      ref={element}
    >
      <div className={clsx('d-flex d-lg-none align-items-center')} data-kt-search-element='toggle'>
        <div className={mobileToggleBtnClass}>
          <KTSVG path='/media/icons/duotune/general/gen021.svg' className='svg-icon-1' />
        </div>
      </div>

      <form
        data-kt-search-element='form'
        className='d-none align-items-center d-lg-flex w-100 mb-5 mb-lg-0 position-relative'
        autoComplete='off'
      >
        <KTSVG
          path='/media/icons/duotune/general/gen004.svg'
          className='svg-icon svg-icon-2 svg-icon-gray-700 position-absolute top-50 translate-middle-y ms-4'
        />

        <input
          type='text'
          className='form-control bg-transparent ps-13 fs-7 h-40px'
          name='search'
          placeholder='Quick Search  (e.g: id: xxxx)'
          data-kt-search-element='input'
        />

        <span
          className='position-absolute top-50 end-0 translate-middle-y lh-0 d-none me-5'
          data-kt-search-element='spinner'
        >
          <span className='spinner-border h-15px w-15px align-middle text-gray-400' />
        </span>

        <span
          className='btn btn-flush btn-active-color-primary position-absolute top-50 end-0 translate-middle-y lh-0 d-none me-4'
          data-kt-search-element='clear'
        >
          <KTSVG
            path='/media/icons/duotune/arrows/arr061.svg'
            className='svg-icon-2 svg-icon-lg-1 me-0'
          />
        </span>
      </form>

      <div
        data-kt-search-element='content'
        data-kt-menu='true'
        className='menu menu-sub menu-sub-dropdown w-300px w-md-350px py-7 px-7 overflow-hidden'
      >
        <div
          className={`${menuState === 'main' ? '' : 'd-none'}`}
          ref={wrapperElement}
          data-kt-search-element='wrapper'
        >
          <div ref={loadingElement} data-kt-search-element='loading' className='text-center d-none'>
            <div className='pb-15 fw-bold'>
              <h3 className='text-gray-600 fs-5 mb-2'>
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                &nbsp;&nbsp; Searching....
              </h3>
            </div>
          </div>
          <div data-kt-search-element='results' ref={resultsElement}>
            {searchResults[0] && <SearchResult searchResults={searchResults} />}
          </div>{' '}
          <div ref={emptyElement} data-kt-search-element='empty' className='text-center d-none'>
            <div className='pb-15 fw-bold'>
              <h3 className='text-gray-600 fs-5 mb-2'>
                <KTSVG
                  path='/media/icons/duotune/files/fil024.svg'
                  className='svg-icon-4x opacity-50'
                />
                No result found
              </h3>
              <div className='text-muted fs-7'>
                Please try again with a different query OR <br />
                <br />
              </div>
              <div className='text-muted fs-7'>
                <a href='/apps/clients/manage' className='btn btn-info'>
                  Create new client
                </a>{' '}
              </div>
            </div>
          </div>
          <div ref={initialMessageElement} data-kt-search-element='main'>
            <div className='text-muted fs-7'>Start typing client details....</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export {Search};
