/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {getAppDataByToken} from '../core/_requests';
import {toAbsoluteUrl} from '../../../../_metronic/helpers';
import {useAuth} from '../core/Auth';

import jwtDecode, {JwtPayload} from 'jwt-decode';

interface IdTokenModel extends JwtPayload {
  email: string;
  family_name: string;
  given_name: string;
  picture: string;
}

export function Login() {
  const [loading, setLoading] = useState(false);
  const [authError, setAuthError] = useState('');
  const {saveAuth, setInitAppData} = useAuth();

  const {hash} = useLocation();

  useEffect(() => {
    const getAppInitData = async (apiToken: string) => {
      const initData = await getAppDataByToken(apiToken);

      if (initData && initData.data) {
        setInitAppData(initData.data);
      }
    };

    try {
      const authError = hash.split('auth_error=');

      if (authError[1]) {
        setAuthError(authError[1]);
      }

      const accessTokenStr = hash.split('access_token=');

      const idTokenString = hash.split('id_token=');

      if (accessTokenStr[1] && idTokenString[1]) {
        let auth = {
          api_token: '',
          id_token: '',
          email: '',
          family_name: '',
          given_name: '',
          picture: '',
        };

        setLoading(true);
        const splitaccessTokenStr = accessTokenStr[1].split('&');
        const api_token = splitaccessTokenStr[0];
        auth.api_token = api_token;

        getAppInitData(api_token);

        const splitidTokenString = idTokenString[1].split('&');
        const id_token = splitidTokenString[0];
        auth.id_token = id_token;

        const idTokenData = jwtDecode<IdTokenModel>(id_token);
        auth.email = idTokenData.email;
        auth.family_name = idTokenData.family_name;
        auth.given_name = idTokenData.given_name;
        auth.picture = idTokenData.picture;

        saveAuth(auth);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  return (
    <>
      {/* begin::Login options */}
      <div className='row g-3 mb-9'>
        {/* begin::Col */}
        <div className='col-md-6'>
          {authError && (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>
                Sorry, looks like there are some errors detected, please try again.
              </div>
            </div>
          )}
          {/* begin::Google link */}
          <a
            href={process.env.REACT_APP_AUTH_LOGIN_URL}
            className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
          >
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
              className='h-15px me-3'
            />

            {!loading && <span className='indicator-label'>Sign in with Google!</span>}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </a>
          {/* end::Google link */}
        </div>
        {/* end::Col */}
      </div>
    </>
  );
}
