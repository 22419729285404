import {useEffect, useState} from 'react';
import {fetchCurrentExchangeRate} from '../core/_requests';

const CurrentExchangeRate = () => {
  const [isCurrentExchangeRateLoading, setCurrentExchangeRateLoading] = useState(true);
  const [currentExchangeRate, setCurrentExchangeRate] = useState<any>({});

  useEffect(() => {
    const getCurrentExchangeRate = async () => {
      try {
        const currentExchangeRate: any = await fetchCurrentExchangeRate('');
        console.log('currentExchangeRate :: ', currentExchangeRate);
        if (currentExchangeRate) {
          setCurrentExchangeRate(currentExchangeRate);
        }
      } catch (error) {
        console.error('Failed to fetch exchange rate', error);
      } finally {
        setCurrentExchangeRateLoading(false);
      }
    };
    getCurrentExchangeRate();
  }, []);

  return (
    <>
      {isCurrentExchangeRateLoading && 'Loading...'}

      {!isCurrentExchangeRateLoading && (
        <div>
          <div className={`card  theme-dark-bg-body`}>
            <div className='card-body d-flex flex-column pt-0 pb-3'>
              <span className='badge badge-dark'>{currentExchangeRate.currency_date}</span>
            </div>
          </div>

          <div className={`card  theme-dark-bg-body`}>
            {/* begin::Body */}
            <div className='card-body d-flex flex-column pt-0  pb-3'>
              {/* begin::Wrapper */}
              <div className='d-flex flex-column flex-grow-1 mb-5'>
                {/* begin::Title                    */}
                Buying {currentExchangeRate.from_currency}
              </div>
              {/* end::Wrapper */}

              {/* begin::Stats */}
              <div className=''>
                {/* begin::Number */}
                <div className='text-dark fw-bolder  me-2 lh-0'>
                  <span className='badge badge-info'>
                    {currentExchangeRate.to_currency} {currentExchangeRate.buying_rate}
                  </span>{' '}
                </div>
                {/* end::Number */}
              </div>
              {/* end::Stats */}
            </div>
          </div>
          <div className={`card  theme-dark-bg-body`}>
            {/* begin::Body */}
            <div className='card-body d-flex flex-column pt-0'>
              {/* begin::Wrapper */}
              <div className='d-flex flex-column flex-grow-1 mb-5'>
                {/* begin::Title                    */}
                Selling {currentExchangeRate.from_currency}
              </div>
              {/* end::Wrapper */}

              {/* begin::Stats */}
              <div className=''>
                {/* begin::Number */}
                <div className='text-dark fw-bolder  me-2 lh-0'>
                  <span className='badge badge-info'>
                    {currentExchangeRate.to_currency} {currentExchangeRate.selling_rate}
                  </span>{' '}
                </div>
                {/* end::Number */}
              </div>
              {/* end::Stats */}
            </div>
          </div>
        </div>
      )}

      {/* <div className='menu-item px-3 my-0'>
        {isCurrentExchangeRateLoading && 'Loading...'}
        <div>
          <span className='badge badge-dark'>{currentExchangeRate.currency_date}</span>
          <span className='badge badge-info'>
            {currentExchangeRate.from_currency} Buying Rate: {currentExchangeRate.to_currency}{' '}
            {currentExchangeRate.buying_rate}
          </span>
          <span className='badge badge-primary m-5'>
            {currentExchangeRate.from_currency} Selling Rate: {currentExchangeRate.to_currency}{' '}
            {currentExchangeRate.selling_rate}
          </span>
        </div>
      </div> */}
    </>
  );
};

export {CurrentExchangeRate};
