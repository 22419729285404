/* eslint-disable react/jsx-no-target-blank */
import {useEffect} from 'react'
import {ILayout, useLayout} from '../../core'
import {KTSVG} from '../../../helpers'

const Footer = () => {
  const {config} = useLayout()
  useEffect(() => {
    updateDOM(config)
  }, [config])
  return (
    <>
      <div className='text-dark order-2 order-md-1'>
        Created with{' '}
        <KTSVG
          path='/media/icons/duotune/general/gen030.svg'
          className=' svg-icon-1 svg-icon-danger'
        />{' '}
        by{' '}
        <a href='https://www.pnrpartners.com.au' target='_blank' title='P & R Partners'>
          P & R Partners
        </a>{' '}
      </div>
    </>
  )
}

const updateDOM = (config: ILayout) => {
  if (config.app?.footer?.fixed?.desktop) {
    document.body.classList.add('data-kt-app-footer-fixed', 'true')
  }

  if (config.app?.footer?.fixed?.mobile) {
    document.body.classList.add('data-kt-app-footer-fixed-mobile', 'true')
  }
}

export {Footer}
