import React, {createContext, useContext} from 'react';

interface MasterLayourContextType {
  flashNotification: string;
  setFlashNotification: (value: string) => void;
}

export const MasterLayourContext = createContext<MasterLayourContextType>({
  flashNotification: '',
  setFlashNotification: () => {},
});

export const useMasterLayourContext = () => useContext(MasterLayourContext);
